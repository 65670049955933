import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

function Title(){
  return(<>
    <h1>ThomasBestwick.co.uk</h1>
    <WelcomeMessage />
    </>);
  
}

function WelcomeMessage(){
  return (<p>Welcome, this is a work in progress!</p>);
  
}
ReactDOM.render(<Title />, document.getElementById('root'));